import { useState } from 'react'
import { useEoValue, useSetEoState } from '~/src/hooks/useEoState'
import { fetchStorefrontsOrders } from '~/src/services/OrderService'
import { FIRM_SELECTOR } from '~/src/stores/firm'
import { Id, OrderSummary } from '@eo-storefronts/eo-core'
import { ORDER_HISTORY } from '~/src/stores/orders'

interface ReturnsType {
  fetch(customerId?: Id): Promise<unknown>,

  loading: boolean,
}

const useFetchAllOrders = (): ReturnsType => {
  const [ loading, setLoading ] = useState<boolean>(false)
  const firm = useEoValue(FIRM_SELECTOR)
  const setOrderHistory = useSetEoState(ORDER_HISTORY)

  const fetch = async (customerId?: Id): Promise<unknown> => {
    if (!customerId) {
      setLoading(false)

      return Promise.resolve()
    }

    setLoading(true)

    return fetchStorefrontsOrders(customerId, firm?.id || null)
      .then((response: OrderSummary[]) => {
        setOrderHistory(response)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return { fetch, loading }
}

export {
  useFetchAllOrders
}
